import { Divider, Grid } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Box } from '@mui/material';
import InspectionImage from './../../Assets/Images/inspection.webp';
import ProductivityImage from './../../Assets/Images/productivity.webp';
import useStyles from './Services.Styles';
import { Link } from 'react-router-dom';

function Services() {
    const classes = useStyles();

    return (
        <main>
            <Grid container spacing={4} alignItems="stretch" justifyContent="center">
                {/* Sampling & Testing Card */}
                <Grid item xs={12} md={4}>
                    <Card className={classes.card}>
                        <Link to="/service-areas#sampling-section" style={{ textDecoration: 'none' }}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="250"
                                    image="/river.webp"
                                    alt="Our Services include State Certified Well Water Testing"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h2" component="h2" className={classes.cardTitle}>
                                        Sampling & Testing
                                    </Typography>
                                    <Typography variant="h3" component="h3" className={classes.cardSubtitle}>
                                        Well Water Testing to Ensure Compliance with Local Health Codes
                                    </Typography>
                                    <Divider style={{ height: 3, marginTop: 10 }} />
                                    <ul className={classes.bulletList}>
                                        <li>Bacterial and Potability Testing</li>
                                        <li>Heavy Metals Testing (upon request)</li>
                                        <li>VA, FHA, USDA & HUD Loan Compliance Testing</li>
                                        <li>State Certified Lab for all Testing</li>
                                    </ul>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                        <CardActions style={{ padding: 0 }}>
                            <Box className={classes.buttonContainer}>
                                <Button
                                    size="small"
                                    className={classes.contactButton}
                                    component={Link}
                                    to="/pricing"
                                >
                                    Pricing
                                </Button>
                                <Button
                                    size="small"
                                    className={classes.contactButton}
                                    component="a"
                                    href="tel:+12088189080"
                                >
                                    (208) 818-9080
                                </Button>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>

                {/* Flow Rate / Productivity Card */}
                <Grid item xs={12} md={4}>
                    <Card className={classes.card}>
                        <Link to="/service-areas#productivity-section" style={{ textDecoration: 'none' }}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="250"
                                    image={ProductivityImage}
                                    alt="Flow Rate Monitoring for Water Testing and Inspection Services"
                                    loading="lazy"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h2" component="h2" className={classes.cardTitle}>
                                        Productivity
                                    </Typography>
                                    <Typography variant="h3" component="h3" className={classes.cardSubtitle}>
                                        Flow Rate Monitoring (GPM)
                                    </Typography>
                                    <Divider style={{ height: 3, marginTop: 10 }} />
                                    <ul className={classes.bulletList}>
                                        <li>HUD 4000.1 Productivity Verification</li>
                                        <li>VA, FHA, USDA & HUD Loan Compliance Testing</li>
                                        <li>System Performance & Flow Rate Analysis</li>
                                        <li>Well Production Testing for Real Estate Sales</li>
                                    </ul>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                        <CardActions style={{ padding: 0 }}>
                            <Box className={classes.buttonContainer}>
                                <Button
                                    size="small"
                                    className={classes.contactButton}
                                    component={Link}
                                    to="/pricing"
                                >
                                    Pricing
                                </Button>
                                <Button
                                    size="small"
                                    className={classes.contactButton}
                                    component="a"
                                    href="tel:+12088189080"
                                >
                                    (208) 818-9080
                                </Button>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>

                {/* System Inspection Card */}

                <Grid item xs={12} md={4}>
                    <Card className={classes.card}>
                        <Link to="/service-areas#system-inspection-section" style={{ textDecoration: 'none' }}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="250"
                                    image={InspectionImage}
                                    alt="Our Services include well Water Testing and System Inspection"
                                    loading="lazy"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h2" component="h2" className={classes.cardTitle}>
                                        System Inspection
                                    </Typography>
                                    <Typography variant="h3" component="h3" className={classes.cardSubtitle}>
                                        Water and Wastewater System Inspection
                                    </Typography>
                                    <Divider style={{ height: 3, marginTop: 10, }} />
                                    <ul className={classes.bulletList}>
                                        <li>Operational Checks and Performance Validation</li>
                                        <li>Consultation and Recommendations for Compliance</li>
                                        <li>Full Compliance with State and Local Requirements</li>
                                        <li>VA, FHA, USDA & HUD Loan Compliance Testing</li>
                                    </ul>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                        <CardActions style={{ padding: 0 }}>
                            <Box className={classes.buttonContainer}>
                                <Button
                                    size="small"
                                    className={classes.contactButton}
                                    component={Link}
                                    to="/pricing"
                                >
                                    Pricing
                                </Button>
                                <Button
                                    size="small"
                                    className={classes.contactButton}
                                    component="a"
                                    href="tel:+12088189080"
                                >
                                    (208) 818-9080
                                </Button>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </main>
    );
}

export default Services;
