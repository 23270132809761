import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Box, Divider } from "@material-ui/core";
import useStyles from "./ServiceAreas.Styles";
import InspectionImg from "../../Assets/Images/inspection.webp";
import ProductivityImg from "../../Assets/Images/productivity.webp";
import CallToAction from "../../Components/CallToAction/CallToAction";
import { Helmet } from "react-helmet";

function ServiceAreas() {

    const location = useLocation();
    const classes = useStyles();

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace("#", "");
            const element = document.getElementById(id);
            if (element) {
                setTimeout(() => {
                    const yOffset = -175; // adjust based on navbar height
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: "smooth" });
                }, 0);
            }
        }
    }, [location]);


    const city = location.pathname.split("/")[1]; // will be '' for /service-areas (general route)
    const isGeneralAreaPage = city === "service-areas" || city === "";

    const formattedCity = !isGeneralAreaPage
        ? (() => {
            const formatted = city
                .split('-')
                .map(word => (word.toLowerCase() === 'd' ? "d'" : word.charAt(0).toUpperCase() + word.slice(1)))
                .join(' ')
                .replace("d' ", "d'");

            const washingtonLocations = [
                "Spokane", "Pend Oreille", "Newport", "Deer Park",
                "Spokane County", "Pend Oreille County"
            ];

            return washingtonLocations.some(loc => formatted.includes(loc))
                ? `${formatted}, Washington`
                : `${formatted}, Idaho`;
        })()
        : null;

    const regionLabel = "North Idaho, Eastern Washington, and Western Montana";

    const mainTitle = isGeneralAreaPage
        ? `Chinook Water Testing offers Certified Well Water Testing, Flow Rate Testing (GPM) & System Inspections. Our Service Areas include all of ${regionLabel}.`
        : `Chinook Water Testing offers Certified Well Water Testing, Flow Rate Testing (GPM) & System Inspections in ${formattedCity}, and the Surrounding Areas. Our Service Areas include all of ${regionLabel}.`;

    return (
        <section className={classes.root}>
            <Helmet>
                <title>
                    {isGeneralAreaPage
                        ? "Certified Well Water Testing & System Inspections in North Idaho & Eastern WA"
                        : `Certified Well Water Testing & System Inspection in ${formattedCity}`}
                </title>
                <meta
                    name="description"
                    content={
                        isGeneralAreaPage
                            ? "Certified well water testing, flow rate testing (GPM), and system inspections across North Idaho, Eastern Washington, and Western Montana. VA, FHA, USDA & HUD compliant."
                            : `Certified well water testing, GPM flow rate testing, and system inspections in ${formattedCity} and nearby areas. VA, FHA, USDA & HUD compliant.`
                    }
                />
                <meta
                    name="keywords"
                    content={
                        isGeneralAreaPage
                            ? "well water testing, system inspections, well inspections, flow rate testing (GPM), North Idaho, Eastern Washington, Western Montana, VA loan water test, FHA loan water testing, HUD testing, real estate water testing"
                            : `well water testing in ${formattedCity}, system inspections in ${formattedCity}, flow rate testing (GPM), VA loan testing, FHA loan water testing, HUD water testing, real estate water inspection`
                    }
                />
                <meta name="robots" content="index, follow" />
                <link
                    rel="canonical"
                    href={
                        isGeneralAreaPage
                            ? "https://chinookwater.com/service-areas"
                            : `https://chinookwater.com/${city}`
                    }
                />
                <meta
                    property="og:title"
                    content={
                        isGeneralAreaPage
                            ? "Certified Well Water Testing & System Inspections in North Idaho & Eastern WA"
                            : `Certified Well Water Testing in ${formattedCity} | Flow Rate Testing (GPM) & System Inspections`
                    }
                />
                <meta
                    property="og:description"
                    content={
                        isGeneralAreaPage
                            ? "Certified well water testing, flow rate testing (GPM), and system inspections across North Idaho, Eastern Washington, and Western Montana. VA, FHA, USDA & HUD compliant."
                            : `Certified well water testing, GPM flow rate testing, and system inspections in ${formattedCity} and nearby areas. VA, FHA, USDA & HUD compliant.`
                    }
                />
                <meta name="author" content="Chinook Water Testing" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Chinook Water Testing" />
                <meta
                    property="og:url"
                    content={
                        isGeneralAreaPage
                            ? "https://chinookwater.com/service-areas"
                            : `https://chinookwater.com/${city}`
                    }
                />
                <meta property="og:image" content="https://chinookwater.com/chinookLogo.jpg" />
                <meta property="og:image:alt" content="Chinook Water Testing company logo" />
            </Helmet>

            <header className={classes.header}>
                <Box className={classes.titleBox}>
                    <Typography variant="h1" className={classes.title}>
                        {mainTitle}
                    </Typography>
                    <Typography className={classes.subText}>
                        Ready to schedule your service<span>{isGeneralAreaPage ? "" : ` in ${formattedCity}`}</span>?
                        <br />
                        <a href="tel:+12088189080" className={`${classes.phoneLink} blink`}>
                            (208) 818-9080
                        </a>
                    </Typography>
                </Box>
            </header>

            <header className="sr-only">
                <h1>
                    {isGeneralAreaPage
                        ? "Certified Well Water Testing, Flow Rate Testing and System Inspections across North Idaho, Eastern Washington, and Western Montana"
                        : `Well Water Testing, Flow Rate Testing and System Inspections in ${formattedCity} and the surrounding areas`}
                </h1>
                <h2>
                    Serving homeowners, loan officers, and real estate agents. VA, FHA, USDA, and HUD loan compliant.
                </h2>
            </header>

            {/* Sampling & Testing Section */}
            <Grid container spacing={4} className={classes.sectionContainer}>
                <Grid item xs={12} id="sampling-section" className={classes.serviceSection}>
                    <Grid container spacing={6} alignItems="center">
                        <Grid item xs={12} md={5}>
                            <img src="/river.webp" alt="All Service Areas include Sampling and Testing" className={classes.sectionImage} />
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.sectionContent}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Water Sampling & Testing
                            </Typography>
                            <Typography variant="body1" className={classes.sectionText}>
                                Chinook Water Testing offers professional well water sampling and lab-certified testing in{' '}
                                <span>{isGeneralAreaPage ? "the Inland Northwest" : `${formattedCity}, and nearby areas`}</span>.
                                Our services ensure your drinking water meets state health codes, real estate transaction standards,
                                and VA, FHA, USDA & HUD loan requirements. Whether you are a homeowner, real estate agent, or loan officer,
                                we deliver accurate, certified results for peace of mind.
                            </Typography>
                            <ul className={classes.bulletList}>
                                <li>Bacterial and Potability Testing</li>
                                <li>Heavy Metals Testing (upon request)</li>
                                <li>VA, FHA, USDA & HUD Loan Compliance Testing</li>
                                <li>State Certified Lab for all Testing</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>

                {/* Flow Monitoring Section */}
                <Grid item xs={12} id="productivity-section" className={classes.serviceSection}>
                    <Grid container spacing={6} alignItems="center">
                        <Grid item xs={12} md={5}>
                            <img src={ProductivityImg} alt="All Service Areas include Flow Rate Testing" className={classes.sectionImage} loading="lazy" />
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.sectionContent}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Flow Rate Monitoring
                            </Typography>
                            <Typography variant="body1" className={classes.sectionText}>
                                Ensuring sufficient water flow (GPM) is critical for homeowners, real estate transactions, and loan approvals. Chinook Water Testing conducts flow rate monitoring to verify that private wells
                                <span>{isGeneralAreaPage ? " " : ` in ${formattedCity}, `}</span>
                                meet productivity standards, including VA, FHA, USDA & HUD home loan requirements. Our testing provides accurate flow rate measurements and detailed reporting to ensure compliance and system efficiency.
                            </Typography>
                            <ul className={classes.bulletList}>
                                <li>HUD 4000.1 Productivity Verification</li>
                                <li>VA, FHA, USDA, and HUD Loan Compliance Testing</li>
                                <li>System Performance & Flow Rate Analysis</li>
                                <li>Well Production Testing for Real Estate Sales</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>

                {/* Inspection Section */}
                <Grid item xs={12} id="system-inspection-section" className={classes.serviceSection}>
                    <Grid container spacing={6} alignItems="center">
                        <Grid item xs={12} md={5}>
                            <img src={InspectionImg} alt="All Service Areas include Water and Wastewater System Inspection" className={classes.sectionImage} loading="lazy" />
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.sectionContent}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                System Inspections
                            </Typography>
                            <Typography variant="body1" className={classes.sectionText}>
                                Chinook Water Testing provides thorough system inspections for private well water and wastewater systems
                                <span>{isGeneralAreaPage ? ". " : ` in ${formattedCity}, and surrounding areas. `}</span>
                                Our certified inspections ensure your water system operates safely, efficiently, and in compliance with state
                                and local regulations. Whether you're buying, selling, or maintaining a property,
                                our inspections help identify potential issues before they become costly problems.
                            </Typography>
                            <ul className={classes.bulletList}>
                                <li>Operational checks and performance validation</li>
                                <li>Consultation and recommendations for compliance</li>
                                <li>Full compliance with state and local requirements</li>
                                <li>Homebuyer and real estate transaction inspection</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Call to Action */}
            <CallToAction />
        </section>
    );
}

export default ServiceAreas;
